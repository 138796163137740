
import React from "react";
import "./loadimage.scss";

class LoadImage extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            loaded: false

        };

        this.mounted = false;
        this.loading = false;
        this.src = "";

    }

    componentDidMount() {

        this.mounted = true;

        const { src, src2x } = this.props;
        const pr = window.devicePixelRatio;

        this.load( pr > 1 && src2x ? src2x : src );

    }

    componentWillUnmount() {

        this.mounted = false;

    }

    componentWillReceiveProps( nextProps ) {

        const { src, src2x } = nextProps;

        if ( src !== this.props.src || src2x !== this.props.src2x ) {

            const pr = window.devicePixelRatio;

            this.setState( { loaded: false } );
            this.load( pr > 1 && src2x ? src2x : src );

        }

    }

    /**
     *   Load an image source.
     * 
     *   @param string src - Image source URL or image name in Media/Photos
     *
     *   @return void
     */

    load = ( src ) => {

        if ( !src ) {

            return;

        }

        this.loading = true;

        const img = new Image();

        img.onload = () => {

            if ( !this.mounted ) {

                return;

            }

            this.setState( { loaded: true } );

            this.loading = false;
            this.props.onLoad( src, img.width, img.height );

        };

        img.onerror = () => {

            if ( !this.mounted ) {
                
                return;

            }

            this.setState( { error: true } );

            this.loading = false;

            this.props.onError( src );

        };

        img.src = this.src = src;

    }

    render() {

        const { className, style } = this.props;
        const src = this.src;

        const CA = [ "LoadImage" ];

        if ( className ) {

            CA.push( className );

        }

        if ( this.state.loaded ) {

            CA.push( "Loaded" );

        }

        const CS = CA.join( " " );
        const styles = Object.assign( {}, style );

        styles.backgroundImage = `url(${src})`;
        
        return(

            <div className={ CS } style={ styles } />

        );

    }

}

LoadImage.defaultProps = {

    className: "",
    onLoad: () => {},
    onError: () => {},
    src: "",
    src2x: "",
    style: {}

};

export default LoadImage;