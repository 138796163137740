
class Broadcast {

    constructor() {

        this.Channel = false;
        this.Listeners = {};

        if ( typeof window[ "BroadcastChannel" ] !== "undefined" ) {

            this.Channel = new window[ "BroadcastChannel" ]( "tabs" );

            this.Channel.onmessage = this.receive;

        }

        else {

            console.error( "BroadcastChannel is not supported in this browser." );

        }

    }

    get = ( event ) => {

        if ( typeof this.Listeners[ event ] === "undefined" ) {

            this.Listeners[ event ] = [];

        }

        return this.Listeners[ event ];

    }

    listen = ( event, callback ) => {

        if ( typeof callback !== "function" ) {

            return;

        }

        const listeners = this.get( event );
        const index = listeners.indexOf( callback );

        if ( index < 0 ) {

            listeners.push( callback );

        }

    }

    receive = (e) => {

        if ( typeof e.data !== "object" || typeof e.data.name !== "string" ) {

            return;

        }

        const listeners = this.get( e.data.name );

        listeners.forEach( callback => {

            callback( e.data.data );

        } );

    }

    remove = ( event, callback ) => {

        const listeners = this.get( event );
        const index = listeners.indexOf( callback );

        if ( index < 0 ) {

            return;

        }

        listeners.splice( index, 1 );

    }

    send = ( name, data ) => {

        if ( !this.Channel ) {

            return;

        }

        const payload = {

            name: name,
            data: data

        };

        this.Channel.postMessage( payload );

    }

}

export default new Broadcast();