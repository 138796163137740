
import React from "react";
import "./icon.scss";

import * as FeatherIcons from "react-feather";

class Icon extends React.Component {

    onClick = (e) => {

        if ( this.props.disabled ) {

            return;

        }

        this.props.onClick(e);

    }

    render() {

        const classNamesArray = [ "Icon" ];

        if ( this.props.className ) {

            classNamesArray.push( this.props.className );

        }

        const classNamesString = classNamesArray.join( " " );

        let feather = this.props.feather;

        let iconComponent = "";

        if ( typeof FeatherIcons[ feather ] === "undefined" ) {

            feather = "Heart";

        }

        iconComponent = FeatherIcons[ feather ];

        const iconElement = React.createElement( iconComponent, {} );

        return(

            <div className={ classNamesString } onClick={ this.onClick }>

                { iconElement }

            </div>

        );

    }

}

Icon.defaultProps = {

    className: "",
    disabled: false,
    feather: "Heart",
    onClick: () => {},
    src: false

};

export default Icon;