
import React from "react";
import "./about.scss";

import Globals from "Classes/Globals";

import Center from "Components/Layout/Center";
import Navigation from "Components/UI/Navigation";

class About extends React.Component {

    constructor( props ) {

        super( props );

        this.items = [

            [ "Spela", "spela" ],
            [ "Tillbaka", "meny" ]

        ];
        
    }

    componentDidMount() {

        Globals.header( true );

    }

    componentWillUnmount() {

        Globals.header( false );

    }

    render() {

        return (

            <Center className="About">

                { Globals.Settings.NordicRail ? (
                
                    <div className="AboutContent">

                        <h2>OM "HAR DU JÄRNKOLL?"</h2>

                        <hr />

                        <p>Den svenska järnvägen står inför omfattande förändringar de närmaste åren när vi tillsammans ska rusta upp, bygga ut och modernisera järnvägen. Men vad kan du om järnvägen?</p>

                        <p>Med vår quiz kan du testa dig själv om du har järnkoll på järnvägens historia, nutid och framtid!</p>
                        
                    </div>

                ) : (
                
                    <div className="AboutContent">

                        <h2>OM VÄLJ RÄTT VÄG</h2>

                        <hr />

                        <p>Sveriges transportsystem växer så det knakar och en fungerande infrastruktur är avgörande för att din dag ska bli som planerat. Genom Trafikverkets quiz kan du testa dig själv och se om du har koll på historien, nutiden eller framtiden kring vårt gemensamma transportsystem. Kan du välja rätt väg?</p>

                        <p>Trafikverket är en myndighet som arbetar på uppdrag av riksdag och regering och ansvarar för den långsiktiga infrastrukturplaneringen i hela Sverige.</p>
                        
                    </div>

                ) }

                <Navigation items={ this.items } { ...this.props } />

            </Center>

        );

    }

}

export default About;