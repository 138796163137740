
import React from "react";
import "./menu.scss";

import Globals from "Classes/Globals";

import Center from "Components/Layout/Center";
import Intro from "Components/Layout/Intro";
import Navigation from "Components/UI/Navigation";

class Menu extends React.Component {

    constructor( props ) {

        super( props );

        this.items = Globals.Settings.NordicRail ? (
            
            Globals.Settings.Mode ? [

                [ "SPELA", "spela" ],
                [ "OM \"HAR DU JÄRNKOLL?\"", "om" ]

            ] : [

                [ "SPELA", "spela" ],
                [ "TOPPLISTA", "topplista" ]

            ]
            
        ) : (
            
            Globals.Settings.Mode ? [

                [ "SPELA", "spela" ],
                [ "OM VÄLJ RÄTT VÄG", "om" ],
                [ "TILLBAKA", "" ]

            ] : [

                [ "SPELA", "spela" ],
                [ "TOPPLISTA", "topplista" ],
                [ "TILLBAKA", "" ]

            ]
            
        );

    }

    componentDidMount() {

        Globals.header( true );
        Globals.var( "player", false );

    }

    componentWillUnmount() {

        Globals.header( false );

    }

    render() {

        return (

            <Center className="Menu">

                <Intro />

                <Navigation items={ this.items } { ...this.props } />

            </Center>

        );

    }

}

export default Menu;