
import React from "react";
import "./errorcounter.scss";

import Icon from "Components/UI/Icon";

class ErrorCounter extends React.Component {

    render() {

        if ( !this.props.maxErrors ) {

            return "";

        }

        const classNamesArray = [ "ErrorCounter" ];

        if ( this.props.className ) {

            classNamesArray.push( this.props.className );

        }

        const classNamesString = classNamesArray.join( " " );

        const dots = [];

        for ( let i = 0; i < this.props.maxErrors; i++ ) {

            dots.push( i < this.props.errors ? (
            
                <div key={ i + "f" } className="Dot Filled">

                    <Icon feather="X" />

                </div>
                
            ) : (

                <div key={ i } className="Dot" />

            ) );

        }

        return (

            <div className={ classNamesString }>

                { dots }

            </div>

        );

    }

}

ErrorCounter.defaultProps = {

    className: "",
    errors: 0,
    maxErrors: 0

};

export default ErrorCounter;