
import React from "react";
import "./footer.scss";

import Trafikverket from "Components/Layout/Trafikverket";

class Footer extends React.Component {

    render() {

        const classNamesArray = [ "Footer" ];

        if ( this.props.show ) {

            classNamesArray.push( "Show" );

        }

        const classNamesString = classNamesArray.join( " " );

        return (

            <div className={ classNamesString }>

                <Trafikverket />

            </div>

        );

    }

}

Footer.defaultProps = {

    show: false

};

export default Footer;