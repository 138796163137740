
import React from "react";

import Sounds from "Classes/Sounds";
import { UCFirst } from "Functions";

import Center from "Components/Layout/Center";

class ReadySetGo extends React.Component {

    constructor( props ) {

        super( props );

        this.ms = 1000;

        this.state = {

            ready: true,
            set: false,
            go: false

        };


        this.mounted = false;

    }

    componentDidMount() {

        this.mounted = true;

        Sounds.play( "readysetgo" );

        this.interval = setInterval( this.next, this.ms );

    }

    componentWillUnmount() {

        Sounds.pause( "readysetgo" );

        this.mounted = false;

    }

    next = () => {

        if ( !this.mounted ) {

            clearInterval( this.interval );

            return;

        }

        if ( this.state.go ) {

            clearInterval( this.interval );

            this.props.onDone();

        }

        else if ( this.state.set ) {

            this.setState( { go: true } );

        }

        else if ( this.state.ready ) {

            this.setState( { set: true } );

        }

        else {

            this.setState( { ready: true } );

        }

    }

    render() {

        const classNamesArray = [ "ReadySetGo" ];

        for ( var key in this.state ) {

            if ( this.state[ key ] ) {

                classNamesArray.push( UCFirst( key ) );

            }

        }

        const classNamesString = classNamesArray.join( " " );

        return (

            <Center className="ReadySetGoContainer">

                <div className={ classNamesString }>

                    <div className="ReadySetGoReady">
                    
                        Klara!
                    
                    </div>

                    <div className="ReadySetGoSet">
                    
                        Färdiga!
                    
                    </div>

                    <div className="ReadySetGoGo">
                    
                        Gå!
                    
                    </div>

                </div>

            </Center>

        );

    }

}

ReadySetGo.defaultProps = {

    onDone: () => {}

};

export default ReadySetGo;