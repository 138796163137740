
import React from "react";
import "./timer.scss";

import Globals from "Classes/Globals";
import { CapFloat } from "Functions";

class Timer extends React.Component {

    constructor( props ) {

        super( props );

        this.Deltas = [];
        this.DurationFrames = 30;
        this.Limit = 0;

    }

    componentDidMount() {

        this.Limit = this.props.limit;

    }

    componentWillReceiveProps( nextProps ) {

        const delta = nextProps.limit - this.props.limit;

        if ( delta ) {

            this.Deltas.push( {

                ms: delta,
                frames: 0,
                label: ( delta < 0 ? "" : "+" ) + Math.round( delta / 1000 ),
                penalty: delta < 0,
                progress: Globals.Settings.AnimateAdditions ? 0 : 1

            } );

        }

    }

    render() {

        let limit = this.Limit || 1;
        let elapsed = this.props.elapsed;

        this.Deltas.forEach( ( delta, index ) => {

            if ( delta.progress >= 1 ) {

                if ( delta.ms > 0 ) {
                
                    limit += delta.ms;

                }

                else {

                    elapsed -= delta.ms;

                }


            }

            else {

                delta.frames++;
                delta.progress = delta.frames / this.DurationFrames;

                if ( delta.ms > 0 ) {
                
                    limit += delta.progress * delta.ms;

                }

                else {

                    elapsed -= delta.progress * delta.ms;

                }

            }

        } );

        const additions = [];
        const labels = [];

        this.Deltas.forEach( ( delta, index ) => {

            const classNamesArray = [ "TimerAddition" ];

            if ( delta.progress >= 1 ) {

                classNamesArray.push( "Added" );

            }

            if ( delta.penalty ) {

                classNamesArray.push( "Penalty" );

            }

            if ( !Globals.Settings.AnimateAdditions ) {

                classNamesArray.push( "Fadeout" );

            }

            const classNamesString = classNamesArray.join( " " );

            const percent = ( Math.abs( delta.progress * delta.ms ) / limit ) * 100 + "%";
            const style = { width: percent };

            additions.push(

                <div key={ "addition" + index } className={ classNamesString } style={ style } />

            );

            labels.push(

                <div key={ "additionlabel" + index } className={ delta.penalty ? "TimerAdditionLabel Penalty" : "TimerAdditionLabel" }>{ delta.label }</div>

            );

        } );

        additions.reverse();

        const remaining = ( ( this.props.limit - this.props.elapsed ) / 1000 ).toFixed( Globals.Rules.TimerDecimals );
        const progress = CapFloat( limit ? elapsed / limit : 0 );

        return (

            <div className="Timer">

                <div className="TimerProgress">

                    <div className="TimerProgressBar" style={{ width: progress * 100 + "%" }} />

                    { additions }

                </div>

                <div className="TimerRemaining">

                    { remaining }

                </div>

                { labels }

            </div>

        );

    }
    
}

Timer.defaultProps = {

    elapsed: 0,
    limit: 0

};

export default Timer;