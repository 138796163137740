
import React from "react";

import Globals from "Classes/Globals";
import Sounds from "Classes/Sounds";
import { PadNumber, SortArrayObjects } from "Functions";

import Buttons from "Components/UI/Buttons";
import Center from "Components/Layout/Center";
import Counter from "Components/Feedback/Counter";
import Icon from "Components/UI/Icon";
import Menu from "Components/Views/Menu";

class DoneHighscore extends React.Component {

    constructor( props ) {

        super( props );

        this.buttons = [

            [ <span><Icon feather="ArrowRight" /><span>Nästa</span></span>, true, "black", "Enter" ]
        ];
        this.highscore = [];

        this.defaultLength = 10;
        this.fireworks = false;
        this.itemHeight = 100;
        this.playedSound = false;

        this.state = {

            height: false,
            highlight: false,
            width: false

        };

    }

    adjust = () => {

        setTimeout( () => {

            if ( !this.refs.container ) {

                return;

            }

            const container = this.refs.container.refs.wrapper;
            const title = this.refs.title;
            const navigation = this.refs.navigation;
            const padding = 20;

            this.setState( {

                height: container.offsetHeight - title.offsetHeight - navigation.offsetHeight - this.itemHeight,
                width: container.offsetWidth - 2 * padding

            } );

        }, 0 );

    }

    componentDidMount() {

        Globals.header( true );

        this.adjust();

        Globals.setClass( "Purple" );

        this.onHighscore( Globals.var( "highscore" ) );

        window.addEventListener( "resize", this.adjust );

    }

    componentWillUnmount() {

        Globals.header( false );

        Globals.setClass( "" );

        window.removeEventListener( "resize", this.adjust );

    }

    merge = ( ignoreActive ) => {

        const highscore = Array.from( this.highscore );
        const player = this.props.player;

        highscore.forEach( ( s, index ) => {
            
            s.index = index
            s.duration = parseInt( s.duration, 10 );
            s.points = parseInt( s.points, 10 );
            
        } );

        if ( player ) {

            highscore.push( {

                id: -1,
                duration: this.props.duration,
                token: player.token,
                name: player.name,
                points: this.props.points

            } );

        }

        SortArrayObjects( highscore, [ "points", "duration" ], [ 1, -1 ] );

        highscore.forEach( ( s, index ) => s.place = index );

        if ( !ignoreActive ) {

            SortArrayObjects( highscore, "index" );

        }

        return highscore;

    }

    newHighscore = ( data ) => {

        const highscore = data || this.merge( true );

        this.highscore = highscore.slice( 0, Globals.setting( "HighscoreLength" ) || this.defaultLength );

    }

    onHighscore = ( data ) => {

        if ( typeof data !== "object" ) {

            return;

        }

        else {

            const highscore = [];

            data.forEach( ( player ) => {

                highscore.push( {

                    duration: player.duration,
                    name: player.name,
                    points: player.points,
                    token: player.token

                } );

            } );

            this.newHighscore( data );
            this.forceUpdate();

        }

    }

    parseItems = ( list, maxLength, columns = 1 ) => {

        const items = [];
        const width = this.state.width / columns;
        const mod = Math.ceil( maxLength / columns );
        const highlight = this.props.player ? this.props.player.token : -1;

        list.forEach( s => {

            const classNamesArray = [ "GameDoneHighscoreItem" ];

            if ( highlight === s.token ) {

                if ( s.place < maxLength ) {

                    this.fireworks = true;

                }

                classNamesArray.push( "Highlight" );

            }

            if ( s.place >= maxLength ) {

                classNamesArray.push( "Out" );

            }

            let x = Math.floor( s.place / mod );
            let y = s.place % mod;
            let m = columns - 1;

            if ( m < x ) {

                x = m;
                y += mod;

            }

            const classNamesString = classNamesArray.join( " " );
            const style = {
                
                top: Math.min( y, mod ) * this.itemHeight + "px",
                left: x * width,
                width: width,
                lineHeight: this.itemHeight + "px"
                
            };

            if ( !s.token ) {

                console.log( "no token", s );

            }

            items.push(

                <div key={ s.token } className={ classNamesString } style={ style }>

                    <div className="GameDoneHighscoreItemContent">

                        <span className="GameDoneHighscoreName">

                            <span className="GameDoneHighscorePlace">
                        
                                { s.place < maxLength ? PadNumber( s.place + 1 ) : "" }

                            </span>
                        
                            { s.name }
                        
                        </span>

                        <Counter className="GameDoneHighscorePoints" count={ s.points + "P" } />

                    </div>

                </div>

            );

        } );

        this.playSound();

        return items;

    };

    playSound = () => {

        if ( this.playedSound ) {

            return;

        }

        if ( this.fireworks ) {

            Sounds.play( "success" );

        }

        else {

            Sounds.play( "gameover" );

        }

        this.playedSound = true;

    }

    render() {

        if ( Globals.Settings.Mode ) {

            return <Menu { ...this.props } />;

        }

        let highscore = this.merge();
        let highscoreLength = Globals.setting( "HighscoreLength" ) || this.defaultLength;

        if ( highscoreLength > highscore.length ) {

            highscoreLength = highscore.length;

        }

        const classNamesArray = [ "GameDoneHighscore" ];

        let columns = 1;
        let height = this.itemHeight * highscoreLength;

        if ( height > this.state.height && this.state.width > 1000 ) {

            columns = 2;
            height = this.itemHeight * Math.ceil( highscoreLength * .5 );

        }

        else if ( height > this.state.height ) {

            highscoreLength = 5;
            height = this.itemHeight * highscoreLength

        }

        this.fireworks = false;

        const style = { height: height + "px" };
        const items = this.parseItems( highscore, highscoreLength, columns );

        if ( this.fireworks ) {

            classNamesArray.push( "Fireworks" );

        }

        const classNamesString = classNamesArray.join( " " );

        return (

            <Center className={ classNamesString } ref="container">

                <h2 ref="title">
                
                    Du fick { this.props.points } poäng!
                
                </h2>

                <div className="GameDoneHighscoreWrapper" style={ style }>

                    { items }

                </div>

                <div ref="navigation">

                    <Buttons
                    
                        arrows={ false }
                        key="buttons"
                        items={ this.buttons }
                        onClick={ this.props.onNext }
                    
                    />

                </div>

            </Center>

        );

    }

}

DoneHighscore.defaultProps = {

    onNext: () => {},
    duration: 0,
    player: {},
    points: 0

};

export default DoneHighscore;