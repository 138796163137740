
import React from "react";
import "./checkbox.scss";

// Allow the text content to be formatted.
import Icon from "Components/UI/Icon";

class Checkbox extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            checked: false

        };

    }

    componentDidMount() {

        this.setState( {

            checked: this.props.checked

        } );

    }

    componentWillReceiveProps( nextProps ) {

        if ( nextProps.checked !== this.props.checked ) {
            
            this.setState( {

                checked: nextProps.checked

            } );

        }

    }

    onClick = (e) => {

        if ( this.props.disabled ) {

            return;

        }

        const checked = !this.state.checked;

        this.setState( {

            checked: checked

        } );

        this.props.onChange( e, checked, this.props.id );

    }

    reset = () => {

        this.setState( {

            checked: this.props.checked

        } );

    }

    render() {

        const classNamesArray = [ "Checkbox" ];

        if ( this.props.className ) {

            classNamesArray.push( this.props.className );

        }

        if ( this.props.disabled ) {

            classNamesArray.push( "Disabled" );

        }

        const classNamesString = classNamesArray.join( " " );

        return (
            
            <div className={ classNamesString }>

                <div className="Input" onClick={ this.onClick }>

                    { this.state.checked ? <Icon feather="Check" /> : "" }

                </div>

                { this.props.text }

            </div>
            
        );

    }

}

Checkbox.defaultProps = {

    checked: false,
    className: "",
    disabled: false,
    id: false,
    onChange: () => {},
    text: ""

};

export default Checkbox;