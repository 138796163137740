
import React from "react";
import "./slider.scss";

import Buttons from "Components/UI/Buttons";
import Icon from "Components/UI/Icon";

class Slider extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            height: 0,
            index: -1

        };

        this.buttons = [

            [ <Icon feather="ArrowLeft" />, -1, "slant", "ArrowLeft" ],
            [ <Icon feather="ArrowRight" />, 1, "slant", "ArrowRight" ]

        ];
        
    }

    componentDidMount() {

        this.setIndex(0);

        window.addEventListener( "resize", this.onResize );

    }

    componentWillUnmount() {

        window.removeEventListener( "resize", this.onResize );

    }

    onResize = () => {

        this.setIndex( this.state.index );

    }

    setIndex = ( index )  => {

        if ( typeof this.refs[ index ] === "undefined" ) {

            return;

        }

        const height = this.refs[ index ].offsetHeight;

        this.setState( {

            height: height,
            index: index

        } );

    }

    switch = ( step ) => {

        let newIndex = this.state.index + step;
        let limit = typeof this.props.children === "object" ? this.props.children.length - 1 : 0;

        if ( newIndex < 0 ) {

            newIndex = limit;

        }

        else if ( newIndex > limit ) {

            newIndex = 0;

        }

        this.setIndex( newIndex );

    }

    render() {

        let children = this.props.children;

        if ( !children || !children.length ) {

            return "";

        }

        else if ( typeof children !== "object" ) {

            children = [ children ];

        }

        const slides = [];

        children.forEach( ( child, index ) => {

            const classNamesArray = [ "Slide" ];

            if ( index < this.state.index ) {

                classNamesArray.push( "Before" );

            }

            else if ( index > this.state.index ) {

                classNamesArray.push( "After" );

            }

            const classNamesString = classNamesArray.join( " " );

            slides.push(

                <div key={ index } ref={ index } className={ classNamesString }>

                    { child }

                </div>

            );

        } );

        return (

            <div className="Slider" style={{ height: this.state.height }}>

                <Buttons
                
                    arrows={ false }
                    disabled={ children.length < 2 }
                    className="SliderButtons"
                    items={ this.buttons }
                    noReverse={ true }
                    onClick={ ( index, step ) => this.switch( step ) }
                
                />

                <div className="Slides">
            
                    { slides }

                </div>
            
            </div>

        );

    }

}

export default Slider;
