import React from "react";
import "./start.scss";
import Globals from "Classes/Globals";
import Center from "Components/Layout/Center";
import Icon from "Components/UI/Icon";

class Start extends React.Component
{
    constructor(props)
    {
        super(props);
        this.items = [
            ["Testa dina kunskaper", "meny", "Award"],
            ["Var med och gör Sverige närmare", "video", "Youtube"],
            //[ "Hur fattar du beslut?", "beslut", "HelpCircle" ]
        ];
    }

    componentDidMount()
    {
        Globals.header(true);
        Globals.var("player", false);
    }

    componentWillUnmount()
    {
        Globals.header(false);
    }

    onClick = (item) =>
    {
        this.props.onNavigate(item);
    }

    render()
    {
        const items = [];
        this.items.forEach(([label, path, feather], index) => {
            items.push(
                <div
                    className="StartItem"
                    key={path + index}
                    onClick={() => this.onClick([label, path])}
                >
                    <Icon className="StartItemIcon" feather={feather} />
                    <div className="StartItemLabel">{label}</div>
                </div>
            );
        });
        return (
            <Center className="Start">
                <div className="StartContent">
                    <h2>UPPTÄCK OSS!</h2>
                    <hr />
                </div>
                <div className="StartItems">
                    {items}
                </div>
            </Center>
        );
    }
}

Start.defaultProps = {
    onNavigate: () => {}
};

export default Start;