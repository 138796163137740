
import Globals from "Classes/Globals";

import { RandomToken } from "Functions";

class API {

    constructor() {

        let url = Globals.Settings.API;

        this.URL = url.match( /\/^/ ) ? url : url + "/";

    }

    /*
     * Parse a object into properly formatted form data.
     * 
     * @param object obj
     * 
     * @return object
     */

    formData = ( obj ) => {

        /*
         * This is a recursive function for parsing a nested object.
         * 
         * @param object formData - A FormData instance.
         * @param object obj - Data to be appended into formData.
         * @param string parent - Optional. Parent data key.
         * 
         * @return object
         */

        const append = ( formData, obj, parent ) => {

            let item, key;

            for ( let k in obj ) {

                item = obj[ k ];
                key = parent ? parent + "[" + k + "]" : k;

                if ( typeof item === "object" ) {

                    append( formData, item, key );

                }

                else {

                    formData.append( key, item ? item : "" );

                }

            }

            return formData;

        }

        // Create a FormData instance and feed obj into it via append().
        const data = new FormData();

        append( data, obj );

        return data;

    }

    /*
     * Make a XHR request to the backend server API.
     * 
     * @param string endpoint
     * @param object data - Optional form data.
     * @param function callback - Called when the request is completed.
     * @param function progress - Called when XHR progress updates.
     * 
     * @return void
     */

    request = ( endpoint, data, callback, progress ) => {

        // Since data is optional, the second parameter may instead
        // be the callback method.
        if ( !callback ) {

            callback = data;

        }

        if ( typeof callback !== "function" ) {

            callback = () => {};

        }

        // Check API credentials.
        if ( !this.URL ) {

            console.error( "No API URL has been configured." );

            callback( {

                error: true,
                errorMsg: "No API URL has been configured."

            } )

            return;

        }

        const url = this.URL + endpoint + "?t=" + RandomToken(8);
        const formData = this.formData( data );
        const xhr = new XMLHttpRequest();

        xhr.open( "POST", url, true );

        // On complete.
        xhr.addEventListener( "load", (e) => {

            // Check for 200.
            if ( xhr.readyState !== XMLHttpRequest.DONE ) {

                return;

            }

            // Check that the response is a proper JSON.
            if ( xhr.response.match( /^[{[]/ ) ) {

                callback( JSON.parse( xhr.response ) );

            }

            else {

                callback( {

                    data: false,
                    error: "Server error.",
                    message: ""

                } );

            }

        }, false );

        // On fail.
        xhr.addEventListener( "error", (e) => {

            callback( {

                data: false,
                error: "Server error.",
                message: ""

            } );

        }, false );

        // On progress update. If a callback has been supplied.
        if ( typeof progress === "function" ) {

            xhr.upload.addEventListener( "progress", (e) => {

                progress( e.loaded, e.total );

            }, false );

        }

        xhr.send( formData );

    }

    /*
     * Load/save local storage.
     * 
     * @param string key
     * @param mixed value - Optional. Saved if set.
     * 
     * @return mixed - The stored value.
     */

    storage = ( key, value = null ) => {

        if ( typeof Storage === "undefined" ) {

            return false;

        }

        if ( value !== null ) {

            localStorage.setItem( key, value );

        }

        else {

            value = localStorage.getItem( key );

        }

        return value;

    }

}

export default new API();