
import React from "react";
import "./center.scss";

class Center extends React.Component {

    render() {

        const classNamesArray = [ "Center" ];

        if ( this.props.className ) {

            classNamesArray.push( this.props.className );

        }

        const classNamesString = classNamesArray.join( " " );

        return(

            <div className={ classNamesString } ref="wrapper">

                <div className="CenterContainer" ref="container">

                    <div className="CenterAlign" ref="align">

                        <div className="CenterContent" ref="content">

                            { this.props.children }

                        </div>

                    </div>

                </div>

            </div>

        );

    }

}

Center.defaultProps = {

    className: ""

};

export default Center;