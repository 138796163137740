
import React from "react";
import "./button.scss";

import { UCFirst } from "Functions";

class Button extends React.Component {

    constructor( props ) {

        super( props );

        this.Pressed = false;

    }

    componentDidMount() {

        window.addEventListener( "touchend", this.onTouchEnd );

    }

    componentWillUnmount() {

        window.removeEventListener( "touchend", this.onTouchEnd );

    }

    onClick = (e) => {

        if ( this.disabled ) {

            return;

        }

        this.props.onClick(e);

    }

    onMouseDown = (e) => {

        if ( this.disabled ) {

            return;

        }

        this.Pressed = true;

        this.props.onMouseDown(e);

    }

    onMouseEnter = (e) => {

        if ( this.disabled ) {

            return;

        }

        this.props.onMouseEnter(e);

    }
    
    onMouseLeave = (e) => {

        if ( this.disabled ) {

            return;

        }

        this.props.onMouseLeave(e);

    }

    onMouseUp = (e) => {

        if ( this.disabled ) {

            return;

        }

        this.Pressed = false;

        this.props.onMouseUp(e);

    }

    onTouchEnd = (e) => {

        if ( !this.Pressed ) {

            return;

        }

        this.onMouseUp(e);

    }

    render() {

        const classNamesArray = [ "Button" ];

        if ( this.props.className ) {

            classNamesArray.push( this.props.className );

        }

        if ( this.props.disabled ) {

            classNamesArray.push( "Disabled" );

        }

        else if ( this.props.active ) {

            classNamesArray.push( "Active" );

        }

        else if ( this.props.selected ) {

            classNamesArray.push( "Selected" );

        }

        const colors = [ "black", "green", "red", "slant" ];

        if ( colors.indexOf( this.props.color ) < 0 ) {

            classNamesArray.push( "Green" );

        }

        else {

            classNamesArray.push( UCFirst( this.props.color ) );

        }

        const classNamesString = classNamesArray.join( " " );

        return(

            <div
            
                className={ classNamesString }
                onClick={ this.onClick }
                onMouseDown={ this.onMouseDown }
                onMouseEnter={ this.onMouseEnter }
                onMouseLeave={ this.onMouseLeave }
                onMouseUp={ this.onMouseUp }
                onTouchStart={ this.onMouseDown }
                
            >

                <div className="ButtonContent">
                
                    { this.props.label || this.props.children }
                    
                </div>

            </div>

        );

    }

}

Button.defaultProps = {

    active: false,
    className: "",
    color: "green",
    disabled: false,
    label: "",
    onClick: () => {},
    onMouseDown: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onMouseUp: () => {},
    selected: false

};

export default Button;
