
import React from "react";
import "./navigation.scss";

class Navigation extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            enterDown: false,
            selectedIndex: -1

        }

    }

    componentDidMount() {

        window.addEventListener( "keydown", this.onKeyDown );
        window.addEventListener( "keyup", this.onKeyUp );

    }

    componentWillUnmount() {

        window.removeEventListener( "keydown", this.onKeyDown );
        window.removeEventListener( "keyup", this.onKeyUp );

    }

    navigate = ( index ) => {

        if ( typeof this.props.items[ index ] !== "object" ) {

            return;

        }

        const item = this.props.items[ index ];
        
        this.props.onNavigate( item );

    }

    onHover = () => {

        this.setState( {

            selectedIndex: -1

        } );

    }

    onKeyDown = (e) => {

        switch ( e.key ) {

            case "ArrowLeft":
            case "ArrowUp":

                this.setIndex( this.state.selectedIndex - 1 );

                break;

            case "ArrowRight":
            case "ArrowDown":
            case "Tab":

                this.setIndex( this.state.selectedIndex + 1 );

                break;

            case "Backspace":

                break;

            case "Enter":

                this.setState( {

                    enterDown: true

                } );

                break;

            default:

                return;

        }

        e.preventDefault();
        e.stopPropagation();

    }

    onKeyUp = (e) => {

        switch ( e.key ) {

            case "Backspace":

                break;

            case "Enter":

                this.setState( {

                    enterDown: false

                } );

                const index = this.state.selectedIndex;

                this.navigate( index < 0 ? 0 : index );

                break;

            default:

                return;

        }

        e.preventDefault();
        e.stopPropagation();

    }

    setIndex = ( index ) => {

        const newIndex = index >= 0 ? index % this.props.items.length : this.props.items.length - 1;

        this.setState( {

            selectedIndex: newIndex

        } );

    }

    render() {

        const items = [];

        this.props.items.forEach( ( [ title, path ], index ) => {

            const classNamesArray = [ "NavigationItem" ];
            
            if ( index === this.state.selectedIndex ) {

                classNamesArray.push( "Selected" );

                if ( this.state.enterDown ) {

                    classNamesArray.push( "Active" );

                }

            }

            const classNamesString = classNamesArray.join( " " );

            items.push(

                <div
                
                    className={ classNamesString }
                    key={ path }
                    onClick={ () => this.navigate( index ) }
                    onMouseEnter={ this.onHover }
                    
                >

                    <span>{ title }</span>

                </div>

            );

        } );

        return (

            <div className="Navigation">

                { items }

            </div>

        );

    }

}

Navigation.defaultProps = {

    items: [],
    onNavigate: () => {}

};

export default Navigation;