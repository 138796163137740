
import Globals from "Classes/Globals";

import SnGameover from "./gameover.mp3";
import SnHeartbeat from "./heartbeat.mp3";
import SnReadySetGo from "./readysetgo.mp3";
import SnRight from "./right.mp3";
import SnSuccess from "./success.mp3";
import SnWrong from "./wrong.mp3";

class Sounds {

    constructor() {

        this.Files = {};

        if ( Globals.Settings.Sounds ) {

            this.load( "gameover", SnGameover );
            this.load( "heartbeat", SnHeartbeat );
            this.load( "readysetgo", SnReadySetGo );
            this.load( "right", SnRight );
            this.load( "success", SnSuccess );
            this.load( "wrong", SnWrong );

            if ( Globals.Settings.Mode ) {
                
                this.mute( Globals.var( "muted" ) );

                Globals.listen( "var-muted", this.mute );

            }

            else {

                this.mute( false );

            }
            
        }

    }

    load = ( name, file ) => {

        const player = new Audio( file );

        player.preload = "auto";

        this.Files[ name ] = player;

    }

    play = ( name, loop ) => {

        if ( !Globals.Settings.Sounds || typeof this.Files[ name ] === "undefined" ) {

            return;

        }

        const player = this.Files[ name ];

        if ( loop && !player.paused ) {

            return;

        }

        player.loop = !!loop;
        player.play();

    }

    pause = ( name, reset ) => {

        if ( !Globals.Settings.Sounds || typeof this.Files[ name ] === "undefined" ) {

            return;

        }

        const player = this.Files[ name ];

        player.pause();
        player.currentTime = 0;
        
    }

    mute = ( doMute ) => {

        if ( Globals.Settings.Debug ) {

            console.log( "Initial Mute", doMute );

        }

        for ( let name in this.Files ) {

            this.Files[ name ].muted = doMute;

        }

    }

}

export default new Sounds();