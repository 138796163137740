
import React from "react";
import "./sharebutton.scss";

import { UCFirst, Url } from "Functions";

import Icon from "Components/UI/Icon";

class ShareButton extends React.Component {

    createUrl = () => {

        let share = this.props.url ? this.props.url : Url();
        let shareUrl = "";

        switch ( this.props.type ) {

            case "twitter":

                let maxLength = 279 - share.length;
                let text = this.props.text.length > maxLength ? this.props.text.substr( 0, 276 ) + "..." : this.props.text;

                share = text + " " + share;

                shareUrl = "https://twitter.com/intent/tweet?text=%share";

                break;
        
            default:

                shareUrl = "https://www.facebook.com/sharer/sharer.php?u=%share";

        }

        return shareUrl.replace( '%share', encodeURI( share ) );

    }

    render() {

        const url = this.createUrl();

        const classNamesArray = [ "ShareButton", UCFirst( this.props.type ) ];
        const classNamesString = classNamesArray.join( " " );

        return (
        
            <a className={ classNamesString } target="_blank" rel="noreferrer noopener" href={ url }>

                <Icon feather={ UCFirst( this.props.type ) } />

            </a>    
            
        );

    }

}

ShareButton.defaultProps = {

    url: "",
    text: "",
    type: "facebook"

};

export default ShareButton;