import React from "react";
import "./debug.scss";
import Globals from "Classes/Globals";
import Questions from "Import/JSON/questions.json";
import QuestionsNr from "Import/JSON/questions_nr.json";

class Debug extends React.Component
{
    render()
    {
        const questions = Array.from(Globals.Settings.NordicRail ? QuestionsNr : Questions);
        return (
            <div className="DebugWrapper">
                <h2>Frågor</h2>
                <table className="Debug">
                    {questions.map(([question, isTrue, correct, source], index) =>
                    (
                        <tr key={index}>
                            <td>{index + 1}.</td>
                            <td>{question}</td>
                            <td>{isTrue ? "Sant" : "Falskt"}</td>
                            <td>{correct}</td>
                            <td>{source}</td>
                        </tr>
                    ))}
                </table>
                <h2>Inställningar</h2>
                <table className="Debug">
                    <tr>
                        <td class="DebugTitleCell">
                            <h3>AnimateAdditions</h3>
                            <p>Extra animationer</p>
                        </td>
                        <td>
                            {Globals.setting("AnimateAdditions") ? "True" : "False"}
                        </td>
                    </tr>
                    <tr>
                        <td class="DebugTitleCell">
                            <h3>API</h3>
                            <p>API-url</p>
                        </td>
                        <td>
                            {Globals.setting("API")}
                        </td>
                    </tr>
                    <tr>
                        <td class="DebugTitleCell">
                            <h3>BindFalse</h3>
                            <p>Knapptryck för 'Falskt'</p>
                        </td>
                        <td>
                            {Globals.setting("BindFalse")}
                        </td>
                    </tr>
                    <tr>
                        <td class="DebugTitleCell">
                            <h3>BindTrue</h3>
                            <p>Knapptryck för 'Sant'</p>
                        </td>
                        <td>
                            {Globals.setting("BindTrue")}
                        </td>
                    </tr>
                    <tr>
                        <td class="DebugTitleCell">
                            <h3>Debug</h3>
                            <p>Debug-läge aktiverat</p>
                        </td>
                        <td>
                            {Globals.setting("Debug")}
                        </td>
                    </tr>
                    <tr>
                        <td class="DebugTitleCell">
                            <h3>Debug</h3>
                            <p>Debug-läge aktiverat</p>
                        </td>
                        <td>
                            {Globals.setting("Debug") ? "True" : "False"}
                        </td>
                    </tr>
                    <tr>
                        <td class="DebugTitleCell">
                            <h3>Environment</h3>
                            <p>App-miljö. Används för att växla mellan webb-läge och mäss-läge.</p>
                            <p>Okt 23 - Ändrad till webb för Nordic Rail</p>
                        </td>
                        <td>
                            {Globals.setting("Environment")}
                        </td>
                    </tr>
                    <tr>
                        <td class="DebugTitleCell">
                            <h3>HighscoreLength</h3>
                            <p>Highscrore-listans längd.</p>
                        </td>
                        <td>
                            {Globals.setting("HighscoreLength")}
                        </td>
                    </tr>
                    <tr>
                        <td class="DebugTitleCell">
                            <h3>HighscoreNavigation</h3>
                            <p>Om highscore-listan ska visas i navigationen.</p>
                        </td>
                        <td>
                            {Globals.setting("HighscoreNavigation") ? "True" : "False"}
                        </td>
                    </tr>
                    <tr>
                        <td class="DebugTitleCell">
                            <h3>Mode</h3>
                            <p>Spelläge. 0 = Fullskärmsläge på mässa, 1 = Webbläge</p>
                        </td>
                        <td>
                            {Globals.setting("Mode")}
                        </td>
                    </tr>
                    <tr>
                        <td class="DebugTitleCell">
                            <h3>NordicRail</h3>
                            <p>Nordic Rail-läge. Växlar frågor och navigation.</p>
                            <p>Okt 23 - Ändrad till False trots Nordic Rail pga temp URL.</p>
                        </td>
                        <td>
                            {Globals.setting("NordicRail") ? "True" : "False"}
                        </td>
                    </tr>
                </table>
            </div>
        );
    }
}

export default Debug;