
import React from "react";
import "./counter.scss";

class Counter extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            count: false,
            previous: false

        }

    }

    componentDidMount() {

        this.setState( {

            count: this.parse( this.props.count ),
            previous: this.parse( this.props.count )

        } );

    }

    componentWillReceiveProps( nextProps ) {

        if ( nextProps.count !== this.props.count ) {

            this.setState( {

                count: this.parse( nextProps.count ),
                previous: this.state.count

            } );

        }

    }

    parse = ( str ) => {

        const arr = str.toString().split( "" );

        arr.reverse();

        return arr;

    }

    render() {

        if ( !this.state.count ) {

            return "";

        }

        const output = [];
        const style = {

            width: this.props.charWidth + "px",
            height: this.props.fontSize + "px",
            fontSize: this.props.fontSize + "px",

        };

        this.state.count.forEach( ( char, index ) => {

            const prev = typeof this.state.previous[ index ] === "undefined" ? false : this.state.previous[ index ];

            if ( prev === char ) {

                output.push(
                
                    <div key={ index } className="CounterChar" style={ style }>
                    
                        <span key={ "n" + char }>{ char }</span>
                        
                    </div>
                    
                );

            }

            else if ( prev === false ) {

                output.push( 
                
                    <div key={ index } className="CounterChar" style={ style }>
                    
                        <span key={ "n" + char } className="Next">{ char }</span>
                        
                    </div>
                
                );

            }

            else {

                output.push( 
                
                    <div key={ index } className="CounterChar" style={ style }>
                    
                        <span key={ "p" + prev } className="Prev">{ prev }</span>
                        <span key={ "n" + char } className="Next">{ char }</span>
                        
                    </div>
                
                );

            }

        } );

        output.reverse();

        const classNamesArray = [ "Counter" ];

        if ( this.props.className ) {

            classNamesArray.push( this.props.className );

        }

        const classNamesString = classNamesArray.join( " " );

        return(

            <div className={ classNamesString } style={{ lineHeight: this.props.fontSize + "px" }}>

                { output }

            </div>

        );

    }

}

Counter.defaultProps = {

    className: "",
    count: 0,
    fontSize: 24,
    charWidth: 14

};

export default Counter;