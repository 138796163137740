
import React from "react";
import "./mute.scss";

import Globals from "Classes/Globals";

import Icon from "Components/UI/Icon";

class Mute extends React.Component {

    componentDidMount() {

        Globals.listen( "var-muted", this.update );

    }

    componentWillUnmount() {

        Globals.remove( "var-muted", this.update );

    }

    toggle = () => {

        const set = !Globals.var( "muted" );

        Globals.var( "muted", set );

    }

    update = () => {

        this.forceUpdate();

    }

    render() {

        if ( !Globals.Settings.Sounds ) {

            return "";

        }

        const classNamesArray = [ "Mute" ];

        if ( this.props.lift ) {

            classNamesArray.push( "Lift" );

        }

        const classNamesString = classNamesArray.join( " " );
        const feather = Globals.var( "muted" ) ? "VolumeX" : "Volume2";

        return (

            <div className={ classNamesString } onClick={ this.toggle }>
            
                <Icon feather={ feather } />
            
            </div>

        );

    }

}

Mute.defaultProps = {

    lift: false

};

export default Mute;

