import React from "react";
import API from "Classes/API";
import Globals from "Classes/Globals";
import { Uri } from "Functions";
import About from "Components/Views/About";
import Admin from "Components/UI/Admin";
import Debug from "Components/Views/Debug";
import Game from "Components/Views/Game";
import Footer from "Components/Views/Footer";
import Header from "Components/Views/Header";
import Highscore from "Components/Views/Highscore";
import Menu from "Components/Views/Menu";
import Mute from "Components/UI/Mute";
import Share from "Components/Views/Share"
import Start from "Components/Views/Start";
import Stories from "Components/Views/Stories";

class Core extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Canvas = false;
        this.state = {
            className: "",
            uri: "",
            showHeader: Globals.ShowHeader
        };
    }

    componentDidMount()
    {
        const Offset = Globals.Settings.UriOffset;
        const Request = Uri(Offset);
        this.onLoad(Request);
        Globals.var("canvas", this.Canvas);
        Globals.listen("load", this.onLoad);
        Globals.listen("header", this.toggleHeader);
        Globals.listen("class", this.onClass);
        if (Globals.Settings.Mode)
        {
            return;
        }
        API.request("game/highscore",
        {
            environment: Globals.Settings.Environment
        }, (response) =>
        {
            Globals.var("highscore", response.data);
        });
    }

    componentWillUnmount()
    {
        Globals.remove("load", this.onLoad);
        Globals.remove("header", this.toggleHeader);
        Globals.remove("class", this.onClass);
    }

    navigate = ([title, uri]) =>
    {
        Globals.load(uri);
    }

    onClass = (className) =>
    {
        this.setState({className: className});
    }

    onLoad = (uri) =>
    {
        this.setState({uri: uri});
    }

    toggleHeader = (show) =>
    {
        this.setState({showHeader: show});
    }

    render()
    {
        const contents = [
            <Header key="header" show={ this.state.showHeader } />,
            <Footer key="footer" show={ this.state.showHeader } />
        ];
        console.log("Uri", this.state.uri);
        switch (this.state.uri) {
            case "debug":
                contents.push(<Debug key="debug"/>);
                break;
            case "video":
                contents.push(<Stories key="stories" onNavigate={this.navigate}/>);
                break;
            case "meny":
                contents.push(<Menu key="menu" onNavigate={this.navigate}/>);
                break;
            case "om":
                contents.push(<About key="about" onNavigate={this.navigate}/>);
                break;
            case "resultat":
                contents.push(<Share key="share" onNavigate={this.navigate}/>);
                break;
            case "spela":
                contents.push(<Game key="game"/>);
                break;
            case "topplista":
                contents.push(<Highscore          
                    key="highscore"
                    navigation={Globals.Settings.HighscoreNavigation}
                    onNavigate={this.navigate}           
                />);     
                break;
            default:
                contents.push(Globals.Settings.NordicRail ? <Menu key="menu" onNavigate={this.navigate}/> : <Start key="start" onNavigate={this.navigate}/>);
        }
        const CA = ["Core"];
        if (this.state.className)
        {
            CA.push(this.state.className);
        }
        if (Globals.Settings.Mode)
        {
            CA.push("WebVersion");
        }
        return (
            <div className={CA.join(" ")} ref={canvas => this.Canvas = canvas}>
                {contents}
                {Globals.Settings.Mode ? <Mute lift={!this.state.showHeader} /> : <Admin />}
            </div>
       );
    }
}

export default Core;