import React from "react";
import "./stories.scss";
import Globals from "Classes/Globals";
import Center from "Components/Layout/Center";
import Icon from "Components/UI/Icon";
import Navigation from "Components/UI/Navigation";
import Youtube from "Components/UI/Youtube";

class Stories extends React.Component
{
    constructor(props)
    {
        super(props);
        this.items = [["Tillbaka", ""]];
        this.playing = false;
        this.stories = [["Var med och gör Sverige närmare", "uPh3Bl5Cs8w"]];
        /*[ "Elisabeth", "BBmhfNGcKPY" ],
        [ "Hanna", "a9NCUuKqR3c" ],
        [ "Georg", "OxpAKKmnrXk" ],
        [ "Michaela", "5na9ylidUdY" ],
        [ "Nicklas", "mcP8qyNnYSw" ],
        [ "Johanna", "oAGiZWdzDu0" ]*/
    }

    componentDidMount()
    {
        Globals.header(true);
        Globals.var("player", false);
    }

    componentWillUnmount()
    {
        Globals.header(false);
    }

    onClose = () =>
    {
        if (!this.playing)
        {
            return;
        }
        this.playing.stop();
        this.playing.setFull(false);
    }

    onDone = (player) =>
    {
        player.setFull(false);
    }

    onPlay = (player) =>
    {
        if (this.playing && this.playing !== player)
        {
            this.playing.stop();
            this.playing.setFull(false);
        }
        player.setFull(true);
        this.playing = player;
    }

    render()
    {
        const items = [];
        const playlist = [];
        console.log("Stories", this.stories.length);
        this.stories.forEach(([title, id], index) =>
        {
            items.push(
                <div className="StoriesItem" key={index}>
                    <div className="StoriesItemTitle">
                        {title}
                    </div>
                    <Youtube id={id} onDone={this.onDone} onPlay={this.onPlay}>
                        <Icon
                            className="StoriesClose"
                            feather="X"
                            onClick={this.onClose}
                        />
                    </Youtube>
                </div>
            );
            playlist.push(id);
        });
        return (
            <Center className="Stories">
                <div className="StoriesItems">
                    <div className="StoriesContent">
                        <h2>VAR MED OCH GÖR SVERIGE NÄRMARE</h2>
                        <hr/>
                    </div>
                    <div className="StoriesItemBig">
                        <Youtube playlist={playlist} onDone={this.onDone} onPlay={this.onPlay} label={this.stories.length > 1 ? "Spela alla" : ""}>       
                            <Icon
                                className="StoriesClose"
                                feather="X"
                                onClick={this.onClose}    
                            />
                        </Youtube>
                    </div>
                    {items.length > 1 ? items : ""}
                </div>
                <Navigation items={this.items} {...this.props}/>
            </Center>
        );
    }
}

export default Stories;