
import React from "react";
import "./highscore.scss";

import Broadcast from "Classes/Broadcast";
import Globals from "Classes/Globals";
import Sounds from "Classes/Sounds";
import { PadNumber, SortArrayObjects } from "Functions";

import Center from "Components/Layout/Center";
import Counter from "Components/Feedback/Counter";
import Menu from "Components/Views/Menu";
import Navigation from "Components/UI/Navigation";

class Highscore extends React.Component {

    constructor( props ) {

        super( props );

        this.items = [

            [ "SPELA", "/spela" ],
            [ "OM VÄLJ RÄTT VÄG", "/om" ],
            [ "TILLBAKA", "meny" ]

        ];

        this.highscore = [];

        this.defaultLength = 10;
        this.itemHeight = 100;
        this.sessions = {};
        this.fireworks = false;

        this.state = {

            height: false,
            highlight: false,
            width: false

        };

    }

    adjust = () => {

        setTimeout( () => {

            if ( !this.refs.container ) {

                return;

            }

            const container = this.refs.container.refs.wrapper;
            const title = this.refs.title;
            const padding = 20;

            this.setState( {

                height: container.offsetHeight - title.offsetHeight - this.itemHeight,
                width: container.offsetWidth - 2 * padding

            } );

        }, 0 );

    }

    componentDidMount() {

        Globals.header( true );

        this.adjust();

        Globals.setClass( "Purple" );

        Broadcast.listen( "start", this.onStart );
        Broadcast.listen( "update", this.onUpdate );
        Broadcast.listen( "done", this.onDone );

        Globals.listen( "var-highscore", this.onHighscore );

        this.onHighscore( Globals.var( "highscore" ) );

        window.addEventListener( "resize", this.adjust );

    }

    componentWillUnmount() {

        Globals.header( false );

        Globals.setClass( "" );

        Broadcast.remove( "start", this.onStart );
        Broadcast.remove( "update", this.onUpdate );
        Broadcast.remove( "done", this.onDone );

        Globals.remove( "var-highscore", this.onHighscore );

        window.removeEventListener( "resize", this.adjust );

    }

    deleteSession( token ) {

        if ( typeof this.sessions[ token ] === "undefined" ) {

            return;

        }

        delete this.sessions[ token ];

    }

    get = ( token ) => {

        return typeof this.sessions[ token ] === "undefined" ? false : this.sessions[ token ];

    }

    merge = ( ignoreActive ) => {

        const highscore = Array.from( this.highscore );

        for ( let token in this.sessions ) {

            if ( ignoreActive && !this.sessions[ token ].done ) {

                continue;

            }

            const live = this.sessions[ token ];
            const session = Object.assign( {

                name: live.player.name,
                points: live.answered - live.errors,
                live: true

            }, live );

            highscore.push( session );

        }

        highscore.forEach( ( s, index ) => {
            
            s.index = index
            s.duration = parseInt( s.duration, 10 );
            s.points = parseInt( s.points, 10 );
            
        } );

        SortArrayObjects( highscore, [ "points", "duration" ], [ 1, -1 ] );

        highscore.forEach( ( s, index ) => s.place = index );

        if ( !ignoreActive ) {

            SortArrayObjects( highscore, "index" );

        }

        return highscore;

    }

    newHighscore = ( data ) => {

        const highscore = data || this.merge( true );

        this.highscore = highscore.slice( 0, Globals.setting( "HighscoreLength" ) || this.defaultLength );

    }

    onDone = (e) => {

        const session = this.get( e.token );

        if ( !session || session.done ) {

            return;

        }

        session.answered = e.answered;
        session.done = true;
        session.duration = e.duration;
        session.errors = e.errors;

        this.newHighscore();
        this.deleteSession( e.token );

        let ranked = false;

        this.highscore.forEach( ( player ) => {

            if ( player.token === e.token ) {

                ranked = true;

            }

        } );

        const playsound = Globals.Settings.SplitScreen;

        if ( playsound && ranked ) {

            Sounds.play( "success" );

        }

        else if ( playsound ) {

            Sounds.play( "gameover" );

        }

        this.setState( {

            highlight: e.token

        } );

    }

    onHighscore = ( data ) => {

        if ( typeof data !== "object" ) {

            return;

        }

        else {

            const highscore = [];

            data.forEach( ( player ) => {

                highscore.push( {

                    duration: player.duration,
                    name: player.name,
                    points: player.points,
                    token: player.token

                } );

            } );

            this.newHighscore( data );
            this.forceUpdate();

        }

    }

    onStart = (e) => {

        this.sessions[ e.token ] = {

            answered: 0,
            done: false,
            errors: 0,
            duration: 0,
            player: e.player,
            token: e.token

        };

        this.setState( {

            highlight: false

        } );

    }

    onUpdate = (e) => {

        const session = this.get( e.token );

        if ( !session || session.done ) {

            return;

        }

        session.answered = e.answered;
        session.duration = e.duration;
        session.errors = e.errors;

        this.forceUpdate();

    }

    parseItems = ( list, maxLength, columns = 1 ) => {

        const items = [];
        const width = this.state.width / columns;
        const mod = Math.ceil( maxLength / columns );

        list.forEach( s => {

            const classNamesArray = [ "HighscoreItem" ];

            if ( s.done && this.state.highlight === s.token ) {

                this.fireworks = true;

                classNamesArray.push( "Highlight" );

            }

            else if ( s.done ) {

                classNamesArray.push( "Done" );

            }

            else if ( s.live ) {

                classNamesArray.push( "Live" );
                
            }

            if ( ( !s.live || s.done ) && s.place >= maxLength ) {

                classNamesArray.push( "Out" );

            }

            let x = Math.floor( s.place / mod );
            let y = s.place % mod;
            let m = columns - 1;

            if ( m < x ) {

                x = m;
                y += mod;

            }

            const classNamesString = classNamesArray.join( " " );
            const style = {
                
                top: Math.min( y, mod ) * this.itemHeight + "px",
                left: x * width,
                width: width,
                lineHeight: this.itemHeight + "px"
                
            };

            if ( !s.token ) {

                console.log( "no token", s );

            }

            items.push(

                <div key={ s.token } className={ classNamesString } style={ style }>

                    <div className="HighscoreItemContent">

                        <span className="HighscoreName">

                            <span className="HighscorePlace">
                        
                                { s.place < maxLength ? PadNumber( s.place + 1 ) : "" }

                            </span>
                        
                            { s.name }
                        
                        </span>

                        <Counter className="HighscorePoints" count={ s.points + "P" } />

                    </div>

                </div>

            );

        } );

        return items;

    };

    render() {

        if ( Globals.Settings.Mode ) {

            return <Menu { ...this.props } />;

        }

        let highscore = this.merge();
        let highscoreLength = Globals.setting( "HighscoreLength" ) || this.defaultLength;

        if ( highscoreLength > highscore.length ) {

            highscoreLength = highscore.length;

        }

        const classNamesArray = [ "Highscore" ];

        let columns = 1;
        let height = this.itemHeight * highscoreLength;

        if ( height > this.state.height && this.state.width > 1000 ) {

            columns = 2;
            height = this.itemHeight * Math.ceil( highscoreLength * .5 );

        }

        else if ( height > this.state.height ) {

            highscoreLength = 5;
            height = this.itemHeight * highscoreLength

        }

        this.fireworks = false;

        const style = { height: height + "px" };
        const items = this.parseItems( highscore, highscoreLength, columns );

        if ( this.state.highlight && this.fireworks ) {

            classNamesArray.push( "Fireworks" );

        }

        if ( this.props.navigation ) {

            classNamesArray.push( "HasNavigation" );

        }

        const classNamesString = classNamesArray.join( " " );
        const showLength = true ? Globals.setting( "HighscoreLength" ) : Math.min( highscoreLength, highscore.length );

        return (

            <Center className={ classNamesString } ref="container">

                <h2 ref="title">Dagens topp { showLength }</h2>

                <div className="HighscoreWrapper" style={ style }>

                    { items }

                </div>

                { this.props.navigation ? <Navigation items={ this.items } { ...this.props } /> : "" }

            </Center>

        );

    }

}

Highscore.defaultProps = {

    navigation: true

}

export default Highscore;