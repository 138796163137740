
import React from "react";
import "./intro.scss";

import Globals from "Classes/Globals";

class Intro extends React.Component {

    render() {

        return (

            <div className="Intro">

                { this.props.justText ? "" : (

                    <span>

                        <h2>{ Globals.Settings.NordicRail ? "HAR DU JÄRNKOLL?" : "VÄLJ RÄTT VÄG" }</h2>

                        <hr />

                    </span>

                ) }

                { Globals.Settings.NordicRail ? (
                
                    <p>Tillsammans ska vi rusta upp, bygga ut och modernisera järnvägen. Men vad kan du om järnvägens historia, nutid och framtid? Testa dig själv om du vet vad som är sant eller falskt.</p>

                ) : (

                    <p>Sveriges transportsystem växer så det knakar och en fungerande infrastruktur är avgörande för att din dag ska bli som planerat. Men vad vet du om läget på Sveriges vägar, järnvägar och färjelinjer? Testa dig själv i Trafikverkets quiz och se om du kan <br /> skilja på sant eller falskt.</p>

                ) }

            </div>

        );

    }

}

Intro.defaultProps = {

    justText: false

};

export default Intro;