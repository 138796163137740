
import React from "react";
import "./header.scss";

//import Logo from "Components/Layout/Logo";

class Header extends React.Component {

    render() {

        const classNamesArray = [ "Header" ];

        if ( this.props.show ) {

            classNamesArray.push( "Show" );

        }

        const classNamesString = classNamesArray.join( " " );

        return (

            <div className={ classNamesString }>

                

            </div>

        );

    }

}

Header.defaultProps = {

    show: false

};

export default Header;