
import React from "react";
import "./admin.scss";

import Globals from "Classes/Globals";

import Icon from "Components/UI/Icon";

class Admin extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            fullscreen: Globals.Fullscreen

        }

    }

    componentDidMount() {

        Globals.listen( "fullscreen", this.onFullscreen );

    }

    onFullscreen = ( isFullscreen ) => {

        this.setState( {

            fullscreen: isFullscreen

        } );

    }

    render() {

        return(

            <div className="Admin">

                <div className="AdminMenu">

                    <Icon feather="Play" onClick={ () => {
                        
                        Globals.reset();
                        Globals.load( "spela" );
                        
                    }}  />

                    <Icon feather="List" onClick={ () => Globals.load( "topplista" ) }  />

                    <Icon
                    
                        feather={ this.state.fullscreen ? "Minimize" : "Maximize" }
                        onClick={ () => Globals.setFullscreen( !this.state.fullscreen ) }
                        
                    />

                </div>

            </div>

        );

    }

}

export default Admin;