
import React from "react";
import "./buttons.scss";

import Globals from "Classes/Globals";

import Button from "Components/UI/Button";

class Buttons extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            activeIndex: -1,
            enterDown: false,
            selectedIndex: -1

        }

        this.binds = {};
        this.pressed = 0;

    }

    componentDidMount() {

        this.Mounted = true;

        window.addEventListener( "keydown", this.onKeyDown );
        window.addEventListener( "keyup", this.onKeyUp );

        this.updateBinds( this.props.items );

    }

    componentWillReceiveProps( nextProps ) {

        this.updateBinds( nextProps.items );

    }

    componentWillUnmount() {

        this.Mounted = false;

        window.removeEventListener( "keydown", this.onKeyDown );
        window.removeEventListener( "keyup", this.onKeyUp );

    }

    navigate = ( index ) => {

        if ( typeof this.props.items[ index ] !== "object" ) {

            return;

        }

        const item = this.props.items[ index ];
        
        this.props.onNavigate( item );

    }

    onActive = ( index ) => {

        if ( !this.Mounted ) {

            return;

        }

        clearTimeout( this.timeout );

        this.setState( {

            activeIndex: index

        } );

    }

    onClick = ( index, e ) => {

        setTimeout( () => {

            if ( !this.Mounted ) {

                return;

            }

            this.props.onClick( index, this.props.items[ index ][1], e );

        }, Globals.Settings.KeyPressDelay );

    }

    onHover = ( index ) => {

        if ( !this.Mounted ) {

            return;

        }

        this.setState( {

            selectedIndex: index

        } );

    }

    onKeyDown = (e) => {

        if ( this.props.block || !this.Mounted ) {

            return;

        }

        const binds = ( key ) => {

            for ( let k in this.binds ) {

                if ( k === key ) {

                    clearTimeout( this.timeout );

                    this.setState( {

                        activeIndex: this.binds[ k ]

                    } );

                    return true;

                }

            }

            return false;

        };

        if ( this.props.arrows ) {

            switch ( e.key ) {

                case "ArrowLeft":
                case "ArrowUp":

                    this.setIndex( this.state.selectedIndex - 1 );

                    break;

                case "ArrowRight":
                case "ArrowDown":
                case "Tab":

                    this.setIndex( this.state.selectedIndex + 1 );

                    break;

                case "Enter":

                    this.setState( {

                        enterDown: true

                    } );

                    break;

                default:

                    if ( !binds( e.key ) ) {

                        return;

                    }

            }

        }

        else if ( !binds( e.key ) ) {

            return;

        }

        e.preventDefault();
        e.stopPropagation();

        this.pressed = ( new Date() ).getTime();

    }

    onKeyUp = (e) => {

        if ( this.props.block || !this.Mounted ) {

            return;

        }

        const binds = ( key ) => {

            for ( let k in this.binds ) {

                if ( k === key ) {

                    this.onClick( this.binds[ k ] );

                    return true;

                }

            }

            return false;

        };

        if ( this.props.arrows ) {

            switch ( e.key ) {

                case "Enter":

                    this.setState( {

                        enterDown: false

                    } );

                    const index = this.state.selectedIndex;

                    this.onClick( index < 0 ? 0 : index );

                    break;

                default:

                    if ( !binds( e.key ) ) {

                        return;

                    }

            }

        }

        else if ( !binds( e.key ) ) {

            if ( Globals.Settings.Debug ) {

                console.log( "Unbound", e.key );

            }

            return;

        }

        e.preventDefault();
        e.stopPropagation();

        const duration = ( new Date() ).getTime() - this.pressed;

        // Trigger a key press animation if the press duration is shorter
        // than KeyIntervalLimit (ms). This is necessary because some input
        // devices trigger KeyDown and KeyUp at the same time.
        if ( duration < Globals.Settings.KeyIntervalLimit ) {

            this.timeout = setTimeout( () => {

                if ( !this.Mounted ) {

                    return;

                }

                this.setState( {

                    activeIndex: -1

                } );

            }, Globals.Settings.KeyPressInterval );

        }

        else {

            clearTimeout( this.timeout );

            this.setState( {

                activeIndex: -1

            } );

        }

    }

    setIndex = ( index ) => {

        if ( !this.Mounted ) {

            return;

        }

        const newIndex = index >= 0 ? index % this.props.items.length : this.props.items.length - 1;

        this.setState( {

            selectedIndex: newIndex

        } );

    }

    updateBinds = ( items ) => {

        if ( typeof items !== "object" ) {

            return;

        }

        this.binds = {};

        items.forEach( ( [ label, value, color, bind ], index ) => {

            if ( bind ) {

                this.binds[ bind ] = index;

            }

        } );

    }

    render() {

        const items = [];
        const classNamesArray = [ "Buttons" ];

        if ( this.props.className ) {

            classNamesArray.push( this.props.className );

        }

        const classNamesString = classNamesArray.join( " " );

        this.props.items.forEach( ( [ label, value, color, bind, disabled ], index ) => {

            items.push(

                <span
                
                    className="ButtonContainer"
                    key={ index }
                    
                >

                    <Button
                    
                        active={ index === this.state.activeIndex || ( index === this.state.selectedIndex && this.state.enterDown ) }
                        color={ color }
                        disabled={ disabled || this.props.disabled }
                        label={ label }
                        onClick={ e => this.onClick( index, e ) }
                        onMouseDown={ e => this.onActive( index, e ) }
                        onMouseEnter={ e => this.onHover( index, e ) }
                        onMouseLeave={ e => this.onHover( -1, e ) }
                        onMouseUp={ e => this.onActive( -1, e ) }
                        selected={ index === this.state.selectedIndex }

                    />

                </span>

            );

        } );

        if ( Globals.Settings.ReverseButtons && !this.props.noReverse ) {

            items.reverse();

        }

        return (

            <div className={ classNamesString }>

                { items }

            </div>

        );

    }

}

Buttons.defaultProps = {

    arrows: true,
    disabled: false,
    block: false,
    className: "",
    items: [],
    noReverse: false,
    onClick: () => {}

};

export default Buttons;
