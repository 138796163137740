
import React from "react";
import "./textfield.scss";

import { RandomToken } from "Functions";

class TextField extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            value: ""

        };

        this.Token = RandomToken();

    }

    componentDidMount() {

        this.setState( {

            value: this.props.value

        } );

    }

    componentWillReceiveProps( nextProps ) {

        if ( nextProps.value !== this.props.value ) {
            
            this.setState( {

                value: nextProps.value

            } );

        }

    }

    focus = () => {

        this.refs.input.focus();

    }

    onBlur = (e) => {

        const value = e.currentTarget.value;

        if ( this.focusvalue !== value ) {

            this.props.onChange( e, value, this.props.id );

        }

        this.props.onBlur( e, this.props.id );

    }

    onFocus = (e) => {

        this.focusvalue = e.currentTarget.value;

        this.props.onFocus( e, this.props.id );

    }

    onInput = (e) => {

        if ( this.props.disabled ) {

            return;

        }

        const value = e.currentTarget.value;

        this.props.onInput( e, value, this.props.id );

        this.setState( {
            
            value: value
            
        } );

    }

    onKeyUp = (e) => {

        if ( e.which === 13 && !this.props.disabled ) {

            const value = e.currentTarget.value;

            this.props.onEnter( e, value, this.props.id );

        }

    }

    reset = () => {

        this.setState( {

            value: this.props.value

        } );

    }

    render() {

        const classNamesArray = [ "TextField" ];

        if ( this.props.className ) {

            classNamesArray.push( this.props.className );

        }

        if ( this.props.disabled ) {

            classNamesArray.push( "Disabled" );

        }

        if ( this.state.value ) {

            classNamesArray.push( "HasValue" );

        }

        const classNamesString = classNamesArray.join( " " );

        const props = {

            className: "Input",
            disabled: this.props.disabled,
            id: this.Token,
            onBlur: this.onBlur,
            onFocus: this.onFocus,
            onInput: this.onInput,
            onKeyUp: this.onKeyUp,
            type: this.props.type,
            value: this.state.value

        };

        return (

            <div className={ classNamesString }>

                <input

                    { ...props }

                    placeholder={ this.props.placeholder }
                    ref="input"

                />

            </div>            

        );

    }

}

TextField.defaultProps = {

    className: "",
    disabled: false,
    id: false,
    onBlur: () => {},
    onChange: () => {},
    onEnter: () => {},
    onFocus: () => {},
    onInput: () => {},
    placeholder: "",
    type: "text",
    value: ""

};

export default TextField;