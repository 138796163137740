import React from "react";
import "./youtube.scss";
// The YouTube player.
// https://www.npmjs.com/package/react-youtube
import YoutubePlayer from "react-youtube";
// Use Icon as play button.
import Icon from "Components/UI/Icon";
// Use LoadImage to display the video poster (preview).
import LoadImage from "Components/Layout/LoadImage";

class Youtube extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            full: false,
            // The YouTube video ID.
            id: false,
            // Playlist
            list: [],
            // Whether the player is fully loaded.
            ready: false,
            // Whether the user has clicked the poster (preview).
            show: false
        };
        // Player options.
        // https://developers.google.com/youtube/player_parameters
        this.options = {
            playerVars: {
                autoplay: 1,
                color: "white",
                loop: 1,
                modestbranding: 1,
                rel: 0
            }
        };
        this.player = false;
    }

    /*
     * Set YouTube video ID on mount.
     * @return void
     */

    componentDidMount()
    {
        const {id, playlist, url} = this.props;
        this.setState({
            id: id || this.parseUrl(url),
            playlist: this.parseList(playlist)
        });
    }

    /*
     * Update the video credentials.
     * @param object nextProps - The updated component properties.
     * @return void
     */

    componentDidUpdate(prevProps) 
    {
        const {id: i1, url: u1} = this.props;
        const {id: i2, url: u2} = prevProps;
        if (i1 !== i2 || u1 !== u2) 
        {
            this.setState({id: i1 || this.parseUrl(u1)});
        }
    }

    /*
     * Callback for when the poster (preview) is clicked.
     * @return void
     */

    onClick = () =>
    {
        this.setState({show: true});
    }

    /*
     * Callback for when the player is fully loaded.
     * @return void
     */

    onReady = (e) =>
    {
        this.player = e.target;
        this.setState({ready: true});
    }

    /*
     * Callback for when the player changes state.
     * @return void
     */

    onStateChange = (e) =>
    {
        const {onDone, onPlay} = this.props;
        switch (e.data)
        {
            //case 0:
            case -1000:
                onDone(this);
                break;
            case 1:
                onPlay(this);
                break;
            default:
        }
    }

    /*
     * Extract the ID from a YouTube video URL.
     * @param string url
     * @return string - The ID.
     */

    parseUrl = (url) =>
    {
        if (typeof url !== "string")
        {
            return false;
        }
        const id = url.match(/[a-z0-9\-_]{8,}/i);
        return id ? id[0] : false;
    }

    parseList = (list) =>
    {
        if (typeof list !== "object" || !list.length)
        {
            return false;
        }
        const parsed = [];
        list.forEach(url =>
        {
            const id = this.parseUrl(url);
            if (!id)
            {
                return;
            }
            parsed.push(id);
        });
        return parsed;
    }

    setFull = (full) =>
    {
        this.setState({full});
    }

    stop = () =>
    {
        if (!this.player)
        {
            return;
        }
        this.player.pauseVideo();
    }
    
    render()
    {
        const {children, label} = this.props;
        const {full, id, playlist, ready, show} = this.state;
        if (!id && (typeof playlist !== "object" || !playlist.length))
        {
            return "";
        }
        const CA = ["YoutubeContainer"];
        // The video poster image can be loaded from this URL.
        const poster = `https://img.youtube.com/vi/${id || playlist[0]}/maxresdefault.jpg`;
        const content = [];
        content.push(
            <div key="poster" className="YoutubePoster" onClick={this.onClick}>
                <LoadImage src={poster}/>
                <div className="YoutubePlay">
                    <Icon className="YoutubePlayIcon" feather="Play"/>
                    <div className="YoutubePlayLabel">{label}</div>
                </div>
            </div>
        );
        const options = Object.assign(this.options, {});
        let videoId = id;
        if (!videoId && typeof playlist === "object")
        {
            const list = Array.from(playlist);
            videoId = list.shift();
            if (list.length > 0)
            {
                options.playerVars.playlist = list.join(",");
            }
        }
        else
        {
            options.playerVars.playlist = videoId;
        }
        // Show YoutubePlayer when the user has clicked the poster (preview). 
        if (show)
        {
            content.push(    
                <div key="player" className="YoutubePlayer">
                    <YoutubePlayer
                        onReady={this.onReady}
                        onStateChange={this.onStateChange}
                        opts={options}
                        videoId={videoId}
                    />
                </div>
            );
            CA.push("Show");
        }
        if (full)
        {
            CA.push("Full");
        }
        if (ready)
        {
            CA.push("Ready");
        }
        return (
            <div className={CA.join(" ")}>
                <div className="Youtube">{content}</div>
                {children}
            </div>
        );
    }
}

Youtube.defaultProps =
{
    // The YouTube video ID.
    id: false,
    label: "",
    onDone: () => {},
    onPlay: () => {},
    playlist: [],
    // The YouTube video URL.
    url: false
};

export default Youtube;