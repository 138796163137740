
import React from "react";
import "./game.scss";

import API from "Classes/API";
import Broadcast from "Classes/Broadcast";
import Globals from "Classes/Globals";
import Sounds from "Classes/Sounds";
import { RandomToken, ShuffleArray } from "Functions";

import Questions from "Import/JSON/questions.json";
import QuestionsNr from "Import/JSON/questions_nr.json";

import Counter from "Components/Feedback/Counter";
import Done from "./done.js";
import DoneHighscore from "./highscore.js";
import ErrorCounter from "Components/Feedback/ErrorCounter";
import Question from "./question.js";
import ReadySetGo from "./readysetgo.js"
import Register from "Components/Views/Register";
import Start from "./start.js";
import Timer from "Components/Feedback/Timer";

class Game extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            answered: 0,
            done: false,
            highscore: false,
            elapsed: 0,
            errors: 0,
            limit: Globals.Rules.TimeLimit * 1000,
            player: false,
            pulse: false,
            ready: false,
            started: false

        }

        this.answered = [];
        this.errors = [];
        this.questions = Array.from( Globals.Settings.NordicRail ? QuestionsNr : Questions );
        this.token = false;

        this.questions.forEach( ( q, index ) => q.index = index );

    }

    componentDidMount() {

        this.setState( {

            player: Globals.var( "player" ),
            ready: false

        } );

        Globals.listen( "var-player", this.onPlayer );

        window.addEventListener( "beforeunload", this.onUnload );

    }

    componentWillUnmount() {

        Globals.remove( "var-player", this.onPlayer );

        window.removeEventListener( "beforeunload", this.onUnload );

    }

    onAnswer = ( isRight ) => {

        if ( this.state.done ) {

            return;

        }

        let answered, errors, limit;

        ( { answered, errors, limit } = this.state );

        answered++;

        this.answered.push( this.questions[ this.state.answered ].index );

        if ( isRight ) {

            Sounds.play( "right" );

            limit += Globals.Rules.Reward * 1000;

        }

        else {

            Sounds.play( "wrong" );

            errors++;

            this.errors.push( this.questions[ this.state.answered ].index );

            limit -= Globals.Rules.Penalty * 1000;

        }

        const state = {
            
            answered: answered,
            errors: errors,
            limit: limit

        };

        const maxQuestions = Globals.Rules.MaxQuestions;
        const maxErrors = Globals.Rules.MaxErrors;

        if ( ( maxQuestions > 0 && answered >= maxQuestions ) || answered >= this.questions.length || ( maxErrors > 0 && errors >= maxErrors ) || limit < 0 ) {

            state.done = true;

            this.setState( state );
            this.onDone();

            this.setState( {

                elapsed: 0,
                limit: Globals.Rules.TimeLimit * 1000

            } );

        }

        else {

            Broadcast.send( "update", {

                token: this.token,
                answered: answered,
                duration: this.state.elapsed,
                errors: errors,
                limit: limit,
                player: this.state.player

            } );

            this.setState( state );

        }

    }

    onDone = () => {

        Globals.header( true );
        Sounds.pause( "heartbeat", true );

        const player = this.state.player;

        API.request( "game/submit", {

            answered: this.answered,
            duration: this.state.elapsed,
            errors: this.errors,
            email: player.email,
            environment: Globals.Settings.Environment,
            name: player.name,
            points: this.state.answered - this.state.errors,
            ready: false,
            token: player.token

        }, ( response ) => {

            const highscore = response.data;

            if ( highscore ) {

                Globals.var( "highscore", highscore );

            }

        } );

        Broadcast.send( "done", {
            
            answered: this.state.answered,
            duration: this.state.elapsed,
            errors: this.state.errors,
            player: this.state.player,
            token: this.token

        } );

        this.token = false;

    }

    onPlayer = ( player ) => {

        this.setState( {

            player: player

        } );

        if ( !player ) {

            this.reset();

        }

    }

    onUnload = () => {

        if ( this.state.started && !this.state.done ) {

            Broadcast.send( "done", {

                token: this.token,
                answered: this.state.answered,
                duration: this.state.elapsed,
                errors: this.state.errors,
                player: this.state.player

            } );

        }

    }

    ready = () => {

        this.setState( {

            ready: true

        } );

    }

    reset = ( started, limit ) => {

        this.setState( {

            answered: 0,
            done: false,
            elapsed: 0,
            errors: 0,
            highscore: false,
            limit: limit,
            started: started

        } );


    }

    restart = ( index, newPlayer ) => {

        this.reset( 0, 0 );

        if ( newPlayer ) {

            Globals.var( "player", false );

        }

        else {

            const player = Globals.var( "player" );

            player.token = RandomToken();

        }
        
        this.setState( {

            ready: false

        } );

    }

    start = () => {

        this.token = RandomToken();

        ShuffleArray( this.questions );

        const now = new Date();
        const started = parseInt( now.getTime(), 10 );
        const limit = Globals.Rules.TimeLimit * 1000;

        this.reset( started, limit );

        window.requestAnimationFrame( this.tick );

        Broadcast.send( "start", {

            token: this.token,
            duration: 0,
            limit: limit,
            player: this.state.player

        } );

        this.answered = [];
        this.errors = [];

    }

    tick = () => {

        if ( this.state.done ) {

            return;

        }

        const now = new Date();
        const time = parseInt( now.getTime(), 10 );

        const elapsed = time - this.state.started;
        const progress = this.state.limit ? elapsed / this.state.limit : 0;
        const done = progress >= 1;
        const pulse = this.state.limit - elapsed < Globals.Rules.StartPulse * 1000;

        if ( pulse ) {

            Sounds.play( "heartbeat", true );

        }

        else {

            Sounds.pause( "heartbeat", true );

        }

        if ( !done && this.state.started ) {

            window.requestAnimationFrame( this.tick );

        }

        else {

            this.onDone();

        }

        this.setState( {

            done: done,
            elapsed: done ? this.state.limit : time - this.state.started,
            pulse: pulse

        } );

    }

    render() {

        const contents = [];

        if ( !this.state.player ) {

            contents.push(

                <Register
                
                    key="register"

                />

            );

        }

        else if ( !this.state.ready ) {

            contents.push(
            
                <Start
                
                    key="start"
                    onStart={ this.ready }
                    
                />
                
            );

        }

        else if ( !this.state.started ) {

            contents.push(
            
                <ReadySetGo
                
                    key="readysetgo"
                    onDone={ this.start }
                    
                />
                
            );

        }

        else if ( !this.state.done ) {

            const [ question, isTrue ] = this.questions[ this.state.answered ];

            contents.push(
            
                <Timer
                
                    key="timer"
                    elapsed={ this.state.elapsed }
                    limit={ this.state.limit }
                    
                />
                
            );

            contents.push(


                <ErrorCounter
                
                    key="errorcounter"
                    className="GameErrorCounter"
                    errors={ this.state.errors }
                    maxErrors={ Globals.Rules.MaxErrors }
                
                />

            );

            contents.push(


                <Counter
                
                    key="counter"
                    className="GameCounter"
                    count={ this.state.answered - this.state.errors }
                    fontSize={ 36 }
                    charWidth={ 20 }
                
                />

            );

            contents.push(
            
                <Question
                
                    key={ "question" + this.state.answered }
                    text={ question }
                    index={ this.state.answered + 1 }
                    isTrue={ isTrue }
                    onAnswer={ this.onAnswer }
                    pulse={ this.state.pulse }
                    
                />
                
            );

        }

        else if ( !Globals.Settings.Mode && !Globals.Settings.SplitScreen && !this.state.highscore ) {

            contents.push(

                <DoneHighscore

                    key="highscore"
                    duration={ this.state.elapsed }
                    onNext={ () => this.setState( { highscore: true } ) }
                    player={ this.state.player }
                    points={ this.state.answered - this.state.errors }

                />

            );

        }

        else {

            contents.push(

                <Done
                
                    errors={ this.errors }
                    key="done"
                    onRestart={ this.restart }
                    player={ this.state.player }
                    points={ this.state.answered - this.state.errors }
                
                />

            );

        }

        return (

            <div className="Game">

                { contents }

            </div>

        );

    }

}

export default Game;