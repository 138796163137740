
import Rules from "Import/JSON/rules.json";
import Settings from "Import/JSON/settings.json";

import ReactGA from "react-ga";
import { SetUri } from "Functions";

class Globals {

    constructor() {

        this.Listeners = {};
        this.Rules = Rules || {};
        this.Settings = Settings || {};
        this.Vars = {};

        this.Fullscreen = false;
        this.ShowHeader = true;

        document.addEventListener( "webkitfullscreenchange", this.onFullscreen );

        this.var( "muted", this.storage( "muted" ) === "true" );

        this.listen( "var-muted", ( muted ) => {

            this.storage( "muted", muted );

        } );

        if ( this.Settings.Mode ) {

            //ReactGA.initialize( "UA-125826476-1" );

        }

    }

    get = ( event ) => {

        if ( typeof this.Listeners[ event ] === "undefined" ) {

            this.Listeners[ event ] = [];

        }

        return this.Listeners[ event ];

    }

    header = ( show ) => {

        this.trigger( "header", this.ShowHeader = show );

    }

    listen = ( event, callback ) => {

        if ( typeof callback !== "function" ) {

            return;

        }

        const listeners = this.get( event );
        const index = listeners.indexOf( callback );

        if ( index < 0 ) {

            listeners.push( callback );

        }

    }

    load = ( uri ) => {

        uri = ( uri || this.Settings.Mode ) ? uri : `/${this.Settings.Environment}/`;

        SetUri( uri );

        const trimmed = uri.replace( /^\/|\/$/, "" );
        const path = trimmed || "/";

        if ( this.Settings.Mode ) {

            ReactGA.pageview( path );

        }

        this.trigger( "load", trimmed );

    }

    onFullscreen = (e) => {

        const isFullscreen = document.webkitFullscreenElement !== null;

        this.trigger( "fullscreen", isFullscreen );

    }

    remove = ( event, callback ) => {

        const listeners = this.get( event );
        const index = listeners.indexOf( callback );

        if ( index < 0 ) {

            return;

        }

        listeners.splice( index, 1 );

    }

    reset = () => {

        this.var( "player", false );

    }

    rule = ( key ) => {

        return typeof this.Rules[ key ] !== "undefined" ? this.Rules[ key ] : 0;

    }

    setClass = ( className ) => {

        this.trigger( "class", className );

    }

    setFullscreen = ( fullscreen ) => {

        const element = document.getElementById( "root" );

        if ( !element || !element.webkitRequestFullscreen ) {

            console.error( "The browser/origin doesn't support the Fullscreen API." );

            return;

        }

        if ( fullscreen ) {

            element.webkitRequestFullscreen( Element.ALLOW_KEYBOARD_INPUT );

        }

        else {

            document.webkitExitFullscreen();

        }

    }

    setting = ( key ) => {

        return typeof this.Settings[ key ] !== "undefined" ? this.Settings[ key ] : false;

    }

    storage = ( key, value = null ) => {

        if ( typeof Storage === "undefined" ) {

            return false;

        }

        if ( value !== null ) {

            localStorage.setItem( key, value );

        }

        else {

            value = localStorage.getItem( key );

        }

        return value;

    }

    trigger = ( event, data ) => {

        const listeners = this.get( event );

        listeners.forEach( callback => {

            callback( data );

        } );

    }

    var = ( key, value ) => {

        if ( value === undefined ) {

            return typeof this.Vars[ key ] !== "undefined" ? this.Vars[ key ] : undefined;

        }

        this.trigger( "var-" + key, value );

        return this.Vars[ key ] = value;

    }

}

export default new Globals();