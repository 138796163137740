
import React from "react";
import "./screenfield.scss";

import Globals from "Classes/Globals";
import { ValidateEmail } from "Functions";

import Buttons from "Components/UI/Buttons";
import Center from "Components/Layout/Center";
import Icon from "Components/UI/Icon";

class ScreenField extends React.Component {

    constructor( props ) {

        super( props )

        this.block = false;
        this.backspace = Globals.Settings.Remap ? "-" : "Backspace";

        this.buttons = [

            [ <span><Icon feather="ArrowRight" /><span>Nästa</span></span>, true, "black", "Enter" ],
            [ <span><Icon feather="ArrowLeft" /><span>Tillbaka</span></span>, false, "black", this.backspace ]

        ];

        this.state = {

            value: ""

        };

        this.remap = {

            'Backspace': [ 'å', 'Å' ],
            '"': '@',
            '™': '@',
            '€': '$',
            '&': '^',
            '/': '&',
            '(': '*',
            ')': '(',
            '=': ')',
            ';': '<',
            ':': '>',
            '<': '`',
            '>': '~',
            '+': '-',
            '?': '_',
            "'": '\\',
            '*': '|'

        };

    }

    componentDidMount() {

        const input = this.refs.input;

        setTimeout( () => {

            input.focus();

        }, 500 );

    }

    disabled = ( index ) => {

        const value = this.state.value;

        switch ( index ) {

            case 0:

                return ( this.props.mandatory && !value ) || ( this.props.type === "email" && value && !ValidateEmail( value ) );

            case 1:

                return !!value;

            default:

                return false;

        }

    }
    
    onButton = ( next ) => {

        if ( next && !this.disabled(0) ) {

            this.props.onNext( this.state.value, this.props.id );

        }

        else if ( !next && !this.disabled(1) ) {

            this.props.onPrevious( this.props.id );

        }

    }

    onInput = (e) => {

        const input = this.refs.input;

        this.setState( {

            value: input.value

        } );

    }

    onKeyDown = (e) => {

        if ( this.remapKey(e) ) {

            return;

        }

        const input = this.refs.input;

        if ( e.key === "Backspace" && input.value ) {

            this.block = true;

            e.stopPropagation();

        }

        else if ( e.key === "Tab" ) {

            e.preventDefault();

        }

    }

    onKeyUp = (e) => {

        if ( this.remapKey(e) ) {

            return;

        }

        if ( this.block ) {

            this.block = false;

            e.stopPropagation();

        }

    }

    remapKey = (e) => {

        if ( !Globals.Settings.Remap ) {

            return false;

        }

        const input = this.refs.input;
        const start = input.selectionStart;
        const end = input.selectionEnd;

        let char, delta;

        switch ( e.key ) {

            case "-":

                if ( e.type === "keydown" && input.value ) {

                    this.block = true;

                }

                else if ( !this.block ) {

                    return false;

                }

                else {

                    this.block = false;

                }

                char = "";
                delta = start !== end ? 0 : -1;

                break;

            default:

                if ( typeof this.remap[ e.key ] === "undefined" ) {

                    return false;

                }

                const r = this.remap[ e.key ];

                if ( typeof r === "object" && r.length > 1 ) {

                    char = e.shiftKey ? r[1] : r[0];

                }

                else {

                    char = r;

                }

                delta = 0;

        }

        e.stopPropagation();
        e.preventDefault();

        if ( e.type !== "keydown" ) {

            return;

        }

        const value = input.value.substr( 0, start + delta ) + char + input.value.substr( end );

        this.setState( {

            value: value

        } );

        input.value = value;

        input.setSelectionRange( start + delta + char.length, start + delta + char.length );

        return true;

    }

    render() {

        this.buttons[0][4] = this.disabled(0);
        this.buttons[1][4] = this.disabled(1);

        const props = {

            className: "Input",
            onInput: this.onInput,
            onKeyDown: this.onKeyDown,
            onKeyUp: this.onKeyUp,
            placeholder: this.props.placeholder,
            ref: "input",
            type: "text"

        };

        const buttons = Array.from( this.buttons );

        if ( !this.props.allowBack ) {

            buttons.pop();

        }

        return (

            <Center className="ScreenField">

                <div className="ScreenFieldContent">

                    { this.props.children }

                </div>

                <input { ...props } />

                <Buttons

                    arrows={ false }        
                    className="ScreenFieldButtons"
                    items={ buttons }
                    onClick={ index => this.onButton( !index ) }
                
                />

            </Center>

        );

    }

}

ScreenField.defaultProps = {

    allowBack: true,
    id: "",
    mandatory: false,
    onNext: () => {},
    onPrevious: () => {},
    placeholder: "",
    type: "text"

};

export default ScreenField;